import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useHistory } from 'react-router'

import { ModeLink } from '../ModeRouter/ModeRouter'

import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useAuth } from '../AuthProvider/AuthProvider'

import CustomerInformationForm from './CustomerInformationForm/CustomerInformationForm'
import SavedCreditCardForm from './SavedCreditCardForm/SavedCreditCardForm'
import AccountHeader from '../AccountHeader/AccountHeader'
import Progress from '../Progress/Progress'

import { formatTel } from '../../utils/form-utils'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'

import styles from './AccountPage.module.css'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

const { toastContainer, toastWrapper } = styles

const getInitialValues = (user = {}) => {
  const { firstName, lastName, email, phone } = user

  return {
    customerFirstName: firstName || '',
    customerLastName: lastName || '',
    customerEmail: email || '',
    customerTel: formatTel(phone) || ''
  }
}

const AccountPage = () => {
  const { user, loading } = useAuth()
  const shouldAllowDeleteAccount = useFlag(LDFlags.OPT_DELETE_ACCOUNT)
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()
  const onClick = () => {
    // if user navigates to account page from new browser window there will not be a location key
    if (!history.location.key || history.location.key === 'default') {
      return history.push(getRestaurantPath())
    }
    return history.goBack()
  }

  return (
    <div className={styles.page}>
      <AccountHeader onClick={onClick} title='My Account' />
      {loading ? (
        <Progress eventData={{ category: 'AccountPage' }} />
      ) : (
        <>
          <CustomerInformationForm initialValues={getInitialValues(user)} />
          <CreditCardProvider>
            <SavedCreditCardForm />
          </CreditCardProvider>
          {shouldAllowDeleteAccount && (
            <div className='pb-2 mx-6'>
              <ModeLink
                mode='delete'
                variant='secondaryWide'
                data-testid='delete-account-link'
              >
                Delete my account
              </ModeLink>
            </div>
          )}
        </>
      )}
      <ToastContainer
        enableMultiContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position={'top-center'}
      />
    </div>
  )
}

export default AccountPage
