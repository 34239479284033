import { useParty } from '../components/PartyProvider/PartyProvider'
import { DDIGlobals } from '../types/DDIGlobals'

export enum InviteLinkType {
  SHARE = 'share',
  QR = 'qr',
  COPY = 'copy'
}

interface TableUrlGenerationParams {
  toastwebBaseUri: DDIGlobals['toastwebBaseUri']
  uniqueIdentifier: DDIGlobals['uniqueIdentifier']
  shortUrl: DDIGlobals['shortUrl']
}

export const generateTableUrl = ({
  toastwebBaseUri,
  shortUrl,
  uniqueIdentifier
}: TableUrlGenerationParams): string =>
  `${toastwebBaseUri}/${shortUrl}/order-and-pay/${uniqueIdentifier}`

interface UTMProperties {
  campaign?: string
  medium?: string
  source?: string
  term?: string
  content?: string
}

export const generateUTMQuery = (
  baseURLString: string,
  { campaign, medium, source, term, content }: UTMProperties
): string => {
  const url = new URL(baseURLString)
  const utmProperties = { campaign, medium, source, term, content }
  Object.entries(utmProperties).forEach(([k, value]) => {
    if (value) {
      url.searchParams.set(`utm_${k}`, value)
    }
  })

  return url.toString()
}

export const useGenerateInviteLink = (
  baseUrl: string,
  linkType: InviteLinkType
): string => {
  const { pin } = useParty()
  if (Object.values(InviteLinkType).indexOf(linkType) === -1) {
    throw new Error(`Unknown link type ${linkType}`)
  }

  return generateUTMQuery(`${baseUrl}/invite/${pin}`, {
    campaign: 'opat_invite',
    medium: 'web',
    source: linkType
  })
}
