import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'

export const useSpiAllowed = () => {
  const spiEnabled = useFlag(LDFlags.USE_SPI)
  const {
    optConfig: {
      paymentConfig: { allowPreauth }
    }
  } = useDDIGlobals()

  return Boolean(spiEnabled && !allowPreauth)
}
