import React, { ReactNode } from 'react'
import { Field } from 'formik'

import { PaymentMethod } from '../../hooks/place-order/use-available-payment-methods'
import { PaymentType } from '../CheckoutForm/PaymentInfo/constants'
import ApplePayNoBorder from '../../assets/apple-pay-logo.svg'
import CreditCardIcon from '../../assets/credit-cards/credit-card-dark.svg'
import ClickToPayIcon from '../../assets/credit-cards/click-to-pay/click-to-pay.svg'
import Radio from '../Form/Radio/Radio'

type PaymentMethodTabId =
  | 'NEW_CREDIT_CARD'
  | 'APPLE_PAY'
  | 'CREDIT_CARD'
  | 'CLICK_TO_PAY'

type PaymentMethodTabDefinition = {
  label: ReactNode
  value: PaymentType
}

type LabelTemplateProps = {
  labelIcon: any
  labelText: string
  iconClassName?: string
}

const LabelTemplate = ({
  labelIcon,
  labelText,
  iconClassName
}: LabelTemplateProps) => {
  return (
    <div className='flex items-center'>
      <div className={`flex items-center ${iconClassName}`}>{labelIcon}</div>
      <span className='type-subhead font-semibold ml-2'>{labelText}</span>
    </div>
  )
}

export const ApplePayLabel = () => {
  return (
    <LabelTemplate
      labelIcon={<ApplePayNoBorder />}
      labelText=''
      iconClassName='w-16 h-6 mr-2 mt'
    />
  )
}

export const CreditCardLabel = () => {
  return (
    <LabelTemplate
      labelIcon={<CreditCardIcon />}
      labelText={'Credit Card'}
      iconClassName='w-8'
    />
  )
}

export const clickToPayLabel = (
  <LabelTemplate
    labelIcon={<ClickToPayIcon />}
    labelText='Click to Pay'
    iconClassName='w-8'
  />
)

const PaymentMethodTabDefinitions: Record<
  PaymentMethodTabId,
  PaymentMethodTabDefinition
> = {
  NEW_CREDIT_CARD: {
    label: 'New Credit Card',
    value: PaymentType.CREDIT_CARD
  },
  APPLE_PAY: {
    label: <ApplePayLabel />,
    value: PaymentType.APPLE_PAY
  },
  CREDIT_CARD: {
    label: <CreditCardLabel />,
    value: PaymentType.CREDIT_CARD
  },
  CLICK_TO_PAY: {
    label: clickToPayLabel,
    value: PaymentType.CLICK_TO_PAY
  }
}

interface PaymentMethodTab {
  id: PaymentMethodTabId
}

const getHasApplePayMethod = (paymentMethods: PaymentMethod[]): boolean => {
  return paymentMethods.some((pm) => pm.type === 'ApplePay')
}

const getHasClickToPayMethod = (paymentMethods: PaymentMethod[]): boolean => {
  return paymentMethods.some((pm) => pm.type === 'ClickToPay')
}

/**
 * Handles the kinds and ordering of payment method tabs
 * to show to the user, based on available payment methods.
 */
function getPaymentMethodTabs(
  paymentMethods: PaymentMethod[],
  spiActive: boolean
): PaymentMethodTab[] {
  const paymentMethodTabs: PaymentMethodTab[] = []

  if (getHasApplePayMethod(paymentMethods) && !spiActive) {
    paymentMethodTabs.push({ id: 'APPLE_PAY' })
  }
  if (getHasClickToPayMethod(paymentMethods)) {
    paymentMethodTabs.push({ id: 'CLICK_TO_PAY' })
  }

  return paymentMethodTabs
}

interface PaymentMethodTabsProps {
  paymentMethods: PaymentMethod[]
  spiActive?: boolean
}

export const PaymentMethodTabs = ({
  paymentMethods,
  spiActive = false
}: PaymentMethodTabsProps) => {
  const paymentMethodTabs = getPaymentMethodTabs(paymentMethods, spiActive)
  const numberOfPaymentMethods = paymentMethodTabs.length

  return (
    <>
      {paymentMethodTabs.map((tab, rank) => {
        const additionalSpace =
          rank + 1 === numberOfPaymentMethods ? '' : ' mb-4'
        const paymentTypeId = `payment-type-${tab.id}`

        return (
          <div key={tab.id} className={`w-full px-2 ${additionalSpace}`}>
            <Field
              data-testid={paymentTypeId}
              labelClassName='h-[72px] border-t border-solid border-t-[#d3d3d3] border-b-0 border-r-0 border-l-0 -mt-2'
              component={Radio}
              name='paymentType'
              id={paymentTypeId}
              label={PaymentMethodTabDefinitions[tab.id].label}
              value={PaymentMethodTabDefinitions[tab.id].value}
            />
          </div>
        )
      })}
      {!spiActive && (
        <div className='flex items-center'>
          <div key='CREDIT_CARD' className='w-full'>
            <Field
              data-testid='payment-type-CREDIT_CARD'
              variant='chunkyleft'
              labelClassName='h-[56px] text-default font-normal'
              component={Radio}
              name='paymentType'
              id='payment-type-CREDIT_CARD'
              label={PaymentMethodTabDefinitions['CREDIT_CARD'].label}
              value={PaymentMethodTabDefinitions['CREDIT_CARD'].value}
            />
          </div>
        </div>
      )}
    </>
  )
}
